var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',[_c('v-card-text',{staticClass:"py-8"},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.update.apply(null, arguments)}}},[_c('v-row',{staticClass:"text-center mb-n3",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-avatar',{attrs:{"size":"100"}},[_c('v-img',{attrs:{"src":!_vm.preview ? _vm.imageSrc : _vm.preview,"max-width":_vm.imageDimensions.width,"max-height":_vm.imageDimensions.height}},[_c('v-fade-transition',[_c('v-overlay',{attrs:{"value":hover,"absolute":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"icon":"","depressed":"","width":"100","height":"100","ripple":false},on:{"click":_vm.pickFile}},[_c('v-icon',{attrs:{"color":"white","size":"40"}},[_vm._v(" mdi-pencil ")])],1)],1)],1)],1)],1)],1)],1)]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"size:5000","name":_vm.$t('profile_photo')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-file-input',{ref:"image",staticClass:"visibility-hidden",attrs:{"accept":"image/x-png,image/gif,image/jpeg,image/jpg"},on:{"change":_vm.previewImage},model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}}),_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('v-row',_vm._l((_vm.fields),function(field){return _c('v-col',{key:field.name,staticClass:"py-2",attrs:{"cols":"12","md":field.col ? field.col : '12'}},[(field.type == 'text')?[_c('validation-provider',{attrs:{"rules":field.rules,"name":field.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"font-15px",attrs:{"error-messages":errors,"hide-details":errors.length <= 0,"label":_vm.$t('profile_input_label_' + field.name),"autocomplete":"chrome-off"},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}})]}}],null,true)})]:_vm._e()],2)}),1),_c('v-row',[_c('v-col',{staticClass:"pt-5",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"type":"submit","loading":_vm.loading,"disabled":invalid,"color":"primary","block":""}},[_vm._v(" "+_vm._s(_vm.$t("update_profile"))+" ")])],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }