<template>
  <v-container>
    <v-card>
      <v-card-text class="py-8">
        <validation-observer ref="observer" v-slot="{ invalid }">
          <form @submit.prevent="update">
            <v-row no-gutters class="text-center mb-n3">
              <v-col cols="12" class="py-0">
                <v-hover v-slot="{ hover }">
                  <v-avatar size="100">
                    <v-img
                      :src="!preview ? imageSrc : preview"
                      :max-width="imageDimensions.width"
                      :max-height="imageDimensions.height"
                    >
                      <v-fade-transition>
                        <v-overlay :value="hover" absolute>
                          <v-row align="center" justify="center">
                            <v-col cols="12">
                              <v-btn
                                icon
                                depressed
                                width="100"
                                height="100"
                                :ripple="false"
                                @click="pickFile"
                              >
                                <v-icon color="white" size="40">
                                  mdi-pencil
                                </v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-overlay>
                      </v-fade-transition>
                    </v-img>
                  </v-avatar>
                </v-hover>

                <validation-provider
                  v-slot="{ errors }"
                  rules="size:5000"
                  :name="$t('profile_photo')"
                >
                  <v-file-input
                    class="visibility-hidden"
                    v-model="files"
                    ref="image"
                    accept="image/x-png,image/gif,image/jpeg,image/jpg"
                    @change="previewImage"
                  ></v-file-input>

                  <span class="red--text">{{ errors[0] }}</span>
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                v-for="field in fields"
                :key="field.name"
                cols="12"
                :md="field.col ? field.col : '12'"
                class="py-2"
              >
                <template v-if="field.type == 'text'">
                  <validation-provider
                    v-slot="{ errors }"
                    :rules="field.rules"
                    :name="field.name"
                  >
                    <v-text-field
                      v-model="field.value"
                      :error-messages="errors"
                      :hide-details="errors.length <= 0"
                      :label="$t('profile_input_label_' + field.name)"
                      autocomplete="chrome-off"
                      class="font-15px"
                    >
                    </v-text-field>
                  </validation-provider>
                </template>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pt-5">
                <v-btn
                  type="submit"
                  :loading="loading"
                  :disabled="invalid"
                  color="primary"
                  block
                >
                  {{ $t("update_profile") }}
                </v-btn>
              </v-col>
            </v-row>
          </form>
        </validation-observer>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "EditProfilePage",
  data() {
    return {
      loading: false,
      preview: null,
      files: [],
      fields: [
        {
          name: "surname",
          type: "text",
          value: "",
          rules: "required:姓|max:50",
          col: 6
        },
        {
          name: "name",
          type: "text",
          value: "",
          rules: "required:名|max:50",
          col: 6
        },
        {
          name: "furigana_surname",
          type: "text",
          value: "",
          rules: "required:セイ|max:50",
          col: 6
        },
        {
          name: "furigana_name",
          type: "text",
          value: "",
          rules: "required:メイ|max:50",
          col: 6
        },
        {
          name: "position",
          type: "text",
          value: "",
          rules: "required:役職|max:250"
        },
        {
          name: "email",
          type: "text",
          value: "",
          rules:
            "required:メールアドレス|email|max:250|only_english_lang_allowed"
        },
        {
          name: "telephone_number",
          type: "text",
          value: "",
          rules: "required:電話番号|enter_half_width_numbers_hyphens"
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["profile"]),
    imageSrc() {
      if (!this.profile.avatar && this.profile.avatar != "") {
        return require("@/assets/images/profile-avatar.svg");
      }
      return this.profile.avatar;
    },
    imageDimensions() {
      return { width: "100%", height: "100%" };
    }
  },
  created() {
    this.getDataFromApi();
  },
  methods: {
    getDataFromApi() {
      this.loading = true;
      this.$store
        .dispatch("PROFILE_GET")
        .then(() => {
          this.assignPageData();
        })
        .finally(() => (this.loading = false));
    },
    assignPageData() {
      this.fields.forEach(field => {
        if (this.profile[field.name]) {
          field.value = this.profile[field.name];
        }
      });
    },
    pickFile() {
      this.$refs.image.$refs.input.click();
    },
    previewImage(e) {
      this.profile.avatar = URL.createObjectURL(e);
      this.preview = URL.createObjectURL(e);
    },
    update() {
      this.$refs.observer.validate();

      this.loading = true;

      let formData = new FormData();

      formData.append("photo", this.files);

      this.fields.forEach(field => {
        if (field.value) {
          formData.append(field.name, field.value);
        }
      });

      this.$store.dispatch("EDIT_PROFILE", formData).then(result => {
        if (result.status === 200) this.$refs.observer.reset();
        this.getDataFromApi();
        this.loading = false;
      });
    }
  }
};
</script>

<style scoped>
::v-deep .v-messages__message {
  padding-bottom: 0 !important;
}
.v-text-field >>> .v-input__control > .v-input__slot::before {
  border-color: #e5e5e5;
}
</style>
